import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Container, Row, Column } from 'styled-bootstrap-components';

import Layout from '../components/Layout';
import Event from '../components/Event';
import { PageHeader, PageHeaderContent, Filters, Filter, Text } from '../styled';

const eventFilters = [
  {
    title: 'Próximos Eventos',
    target: 'futureEvents',
  },
  {
    title: 'Eventos Pasados',
    target: 'pastEvents',
  }
]

class EventsPage extends Component {
  state = {
    futureEvents: [],
    pastEvents: [],
    active: '',
    filters: [],
  }

  componentDidMount = () => {
    const now = new Date();
    const today = now.getTime();

    const pastEvents = this.props.data.allWordpressWpEvent.edges.filter(event => {
      const date = new Date(event.node.acf.date);
      const eventDate = date.getTime();

      return eventDate < today;
    });

    const futureEvents = this.props.data.allWordpressWpEvent.edges.filter(event => {
      const date = new Date(event.node.acf.date);
      const eventDate = date.getTime();

      return eventDate > today;
    });

    const active = futureEvents.length ? 'futureEvents' : 'pastEvents';

    this.setState({
      futureEvents,
      pastEvents,
      active,
    })
  }


  render() {
    const { data, location } = this.props;
    const events = this.state.active === 'futureEvents' ? this.state.futureEvents : this.state.pastEvents;

    return (
      <Layout location={location}>
        <Helmet title={`${data.wordpressPage.title} - Eddy Herrera`} />
        <PageHeader padding="0" overflow="hidden">
          {data.wordpressPage.featured_media && (
            <Img
              sizes={data.wordpressPage.featured_media.localFile.childImageSharp.sizes}
              alt={data.wordpressPage.featured_media.alt_text}
            />
          )}
          <PageHeaderContent>
            <Container>
              <Row>
                <Column>
                  <h1>{data.wordpressPage.title}</h1>
                </Column>
              </Row>
            </Container>
          </PageHeaderContent>
        </PageHeader>
        <Container>
          <Row>
            <Column sm={12}>
              <Filters mb="1.5rem">
                  {eventFilters.map(filter => (
                    <Filter key={filter.title} onClick={() => this.setState({ active: filter.target })} active={this.state.active === filter.target}>
                      {filter.title}
                    </Filter>
                  ))}
                </Filters>
            </Column>
            <Column sm={9}>
              {events.length ? events.map(event => <Event key={event.node.id} event={event.node} />) : <Text>No hay eventos disponibles.</Text>}
            </Column>
            {/* <Column sm={3}>Buscar por fecha:</Column> */}
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query EventsPageQuery {
    wordpressPage(slug: { eq: "itinerario" }) {
      title
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            sizes(maxWidth: 1440) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
    allWordpressWpEvent(sort: { fields: acf___date }) {
      edges {
        node {
          id
          title
          slug
          acf {
            date
            time
          }
        }
      }
    }
  }
`;

export default EventsPage;

import React from 'react';
import { Link } from 'gatsby';
import moment from 'moment';
import 'moment/locale/es';
import styled from 'styled-components';
import { Text } from '../../styled';
import theme from '../../styled/theme';

const Wrapper = styled(Link)`
  background: white;
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;

  &:hover {
    box-shadow: 0 0 20px rgba(37, 39, 41, 0.1);
  }
`;

const Content = styled.div`
  padding: 1rem 2rem;
`;

const Date = styled.div`
  border-right: 1px solid ${theme.colors.grayLight};
  padding: 1rem 2rem;
  text-align: center;
  text-transform: capitalize;
  line-height: 1.625em;
  min-width: 10%;
`;

const Event = ({ event }) => {
  const day = moment(event.acf.date).format('D');
  const month = moment(event.acf.date).format('MMM');
  const year = moment(event.acf.date).format('Y');

  return (
    <Wrapper to={`/itinerario/${event.slug}`}>
      <Date>
        <Text color="primary">
          <b>{month}</b>
        </Text>
        <Text color="primary" fontSize="2rem">
          <b>{day}</b>
        </Text>
        <Text color="gray">{year}</Text>
      </Date>
      <Content>
        <Text
          dangerouslySetInnerHTML={{ __html: event.title }}
          color="grayDark"
          fontSize="1.125rem"
          fontWeight="bold"
        />
        <Text color="gray">
          {event.acf.time && (
            <span>
              {event.acf.time}
              {/* - */}
            </span>
          )}
          {/* {event.acf.location && event.acf.location.address} */}
        </Text>
      </Content>
    </Wrapper>
  );
};

export default Event;
